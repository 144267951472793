import { colorPalette } from "gx-npm-common-styles";

const brandingHeaderStyles = {
  brandingRoot: {
    alignItems: "center",
    backgroundColor: colorPalette.brand.gartnerBlue.hex,
    display: "flex",
    justifyContent: "space-between",
    minHeight: "72px",
    paddingRight: "24px",
    position: "relative" as const,
    zIndex: 1105,
  },
  brandingLeftContainer: {
    display: "flex",
    alignItems: "center",
    "& svg path": {
      fill: colorPalette.basic.white.hex,
    },
    "& span": {
      color: colorPalette.basic.white.hex,
      paddingLeft: "6px",
    },
    "& .gx-button.btn-tertiary:hover": {
      backgroundColor: colorPalette.brand.gartnerBlue.hex,
    },
    "& .gx-button.btn-tertiary:focus": {
      backgroundColor: colorPalette.brand.gartnerBlue.hex,
    },
  },
  brandingSeparator: {
    width: "1px",
    height: "72px",
    backgroundColor: colorPalette.basic.white.hex,
    marginRight: "24px",
    opacity: 0.25,
  },
};

export default brandingHeaderStyles;
