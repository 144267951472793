import { colorPalette } from "gx-npm-common-styles";

const surveyWorkspaceHeaderAppStyles = {
  headerShiftedForPreview: {
    top: 48,
  },
  headerContent: {
    position: "fixed" as const,
    transition: "all 0.3s ease-in-out",
    width: "100%",
    zIndex: 1101,
  },
  collapseContainer: {
    borderBottom: `1px solid var(${colorPalette.neutrals.stone.name})`,
    "&$collapsedScrolling": {
      borderBottom: "none",
      boxShadow: "0px 4px 12px rgba(20, 19, 18, 0.16)",
    },
  },
  collapsedScrolling: {},
  staticHeaderSpacer: {
    height: "212px", // base height +72px +92px +48px
    position: "static" as const,
    "&$legalAlertSpacer": { height: "308px" }, // +96px
    "&$legalAlertSpacer$closedStatusSpacer": { height: "356px" }, // +96px +48px
    "&$legalAlertSpacer$scrollingSpacer": { height: "264px" }, // +96px -44px
    "&$legalAlertSpacer$closedStatusSpacer$scrollingSpacer": { height: "312px" }, // +96px +48px -44px
    "&$closedStatusSpacer": { height: "260px" }, // +48px
    "&$closedStatusSpacer$scrollingSpacer": { height: "216px" }, // +48px -44px
    "&$scrollingSpacer": { height: "168px" }, // +92px
  },
  closedStatusSpacer: {},
  legalAlertSpacer: {},
  scrollingSpacer: {},
  previewBtmMargin: {
    marginBottom: 48,
  },
};

export default surveyWorkspaceHeaderAppStyles;
