import { Dispatch, SetStateAction } from "react";
import { SurveyStatus } from "./app.constants";
import { InitState, UUID } from "gx-npm-lib";
// eslint-disable-next-line import/no-unresolved
import * as H from "history";

export enum TabNames {
  NONE = "",
  OVERVIEW = "Overview",
  QUESTIONNAIRE = "Questionnaire",
  DOCUMENTS = "Documents",
}

export type AppContextValue = {
  initId: UUID;
  initProdId: UUID;
  initState: InitState;
  isHeaderDataLoaded: boolean;
  isSavingIndicator: boolean;
  isSnackbarOpen: boolean;
  isOnboardingDrawerOpen: boolean;
  isScrolling: boolean;
  isPreviewMode: boolean;
  isSurveyLegalDismissed: boolean;
  location: H.Location;
  savingCount: number;
  selectedTab: TabNames | "";
  surveyHasRequestedDocs: boolean;
  surveyId: UUID;
  surveyRequestedByCompanyName: string;
  surveyRequestedByEmail: string;
  surveyRequestedByUserName: string;
  surveyStatus: SurveyStatus;
  setIsHeaderDataLoaded: Dispatch<SetStateAction<boolean>>;
  setIsSavingIndicator: Dispatch<SetStateAction<boolean>>;
  setIsSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setInitId: Dispatch<SetStateAction<UUID>>;
  setInitProdId: Dispatch<SetStateAction<UUID>>;
  setInitState: Dispatch<SetStateAction<InitState>>;
  setSavingCount: Dispatch<SetStateAction<number>>;
  setSurveyHasRequestedDocs: Dispatch<SetStateAction<boolean>>;
  setSurveyId: Dispatch<SetStateAction<UUID>>;
  setSurveyRequestedByCompanyName: Dispatch<SetStateAction<string>>;
  setSurveyRequestedByUserName: Dispatch<SetStateAction<string>>;
  setSurveyRequestedByEmail: Dispatch<SetStateAction<string>>;
  setSurveyStatus: Dispatch<SetStateAction<SurveyStatus>>;
  setIsOnboardingDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setIsPreviewMode: Dispatch<SetStateAction<boolean>>;
  setIsScrolling: Dispatch<SetStateAction<boolean>>;
  setIsSurveyLegalDismissed: Dispatch<SetStateAction<boolean>>;
  setSelectedTab: Dispatch<SetStateAction<TabNames | "">>;
};

export enum CustomEvents {
  QUESTIONNAIRE_EDIT_COUNT = "QUESTIONNAIRE_EDIT_COUNT",
}

export type SurveyPreviewItem = {
  id: UUID;
  index: number;
  isDeleted?: boolean;
  name: string;
};

export type RequirementCategoryDto = {
  id: UUID;
  name: string;
  comment: string;
  items: RequirementItemType[];
};

export type RequirementItemType = {
  id: UUID;
  name: string;
  comment: string;
  responseOptionId: UUID;
  description: string;
};

export type SurveyPreviewData = {
  companyName: string;
  instructions: string;
  requestedDocuments: SurveyPreviewItem[];
  responseOptions: SurveyPreviewItem[];
  selectedCategories: RequirementCategoryDto[];
  isValid: boolean;
  requirementsCount: number;
  initiativeId: string;
};

export type SurveyPreviewHeaderResponseDTO = {
  initState: InitState;
  hasRequestedDocuments: boolean;
  surveyId: UUID;
  requestedByCompanyName: string;
  requestedByEmail: string;
  requestedByUserName: string;
  surveyStatus: SurveyStatus;
  hasRequirements: boolean;
};
