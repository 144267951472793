/**
 * @deprecated
 */
export enum AppPaths {
  QUESTIONNAIRE_OVERVIEW_TAB = "/s/questionnaire-overview",
  QUESTIONNAIRE_RESPONSES_TAB = "/s/questionnaire-responses",
  QUESTIONNAIRE_DOCUMENTS_TAB = "/s/questionnaire-documents",
  TASKS_DASHBOARD = "/s/tasks-dashboard",
}

export enum NewAppPaths {
  QUESTIONNAIRE_OVERVIEW_TAB = "/overview",
  QUESTIONNAIRE_RESPONSES_TAB = "/responses",
  QUESTIONNAIRE_DOCUMENTS_TAB = "/documents",
  TASKS_DASHBOARD = "/s/tasks-dashboard",
}

export enum SurveyStatus {
  DEFAULT = "",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
}

export enum ClientEvents {
  SURVEY_HEADER_QUICKSTART_MODAL_OPENED = "SURVEY_HEADER_QUICKSTART_MODAL_OPENED",
  SURVEY_HEADER_QUICKSTART_OVERVIEW_CLICKED = "SURVEY_HEADER_QUICKSTART_OVERVIEW_CLICKED",
  SURVEY_HEADER_QUICKSTART_SHARING_RESPONSE_CLICKED = "SURVEY_HEADER_QUICKSTART_SHARING_RESPONSE_CLICKED",
  SURVEY_HEADER_QUICKSTART_DATA_PRIVACY_CLICKED = "SURVEY_HEADER_QUICKSTART_DATA_PRIVACY_CLICKED",
  SURVEY_HEADER_QUICKSTART_MODAL_CLOSED = "SURVEY_HEADER_QUICKSTART_MODAL_CLOSED",
}
