import {
  GartnerFooter,
  Paragraph,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import classes from "./preview-error.module.scss";
import PreviewErrorImg from "../../assets/images/preview-error.svg";
import { useTranslation } from "react-i18next";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";
import React from "react";

const PreviewError = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.errorContainer}>
        <img src={PreviewErrorImg} alt={t("Questionnaire Error")} />
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent
              rootClassName={classes.paragraphGCOM3606}
              styling={"h4"}
              color={"iron"}
              boldness={"medium"}
            >
              {t("Please complete your questionnaire set up to access your preview.")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph boldness={"semi"} type={"p1"} rootClassName={classes.paragraph}>
              {t("Please complete your questionnaire set up to access your preview.")}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={classes.footer}>
        <GartnerFooter />
      </div>
    </div>
  );
};

export default PreviewError;
