import { useTranslation } from "react-i18next";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "./data-privacy.styles.module.scss";
import React from "react";

const DataPrivacyComponent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <TypographyComponent type={"h5"} boldness={"semi"}>
        {t("Data Privacy")}
      </TypographyComponent>
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
          {t(
            "Gartner may use vendor responses provided via BuySmart to inform Gartner research (including research specific to that vendor) and to improve Gartner products and services. Your responses to the questionnaire will not be available to other vendors or to other evaluation teams."
          )}
        </TypographyComponent>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about how your data will be used")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/7211528-buysmart-data-privacy-security-policy"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DataPrivacyComponent;
