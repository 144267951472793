import { colorPalette } from "gx-npm-common-styles";

const ironHex = colorPalette.neutrals.iron.hex;
const carbonHex = colorPalette.neutrals.carbon.hex;
const stoneHex = colorPalette.neutrals.stone.hex;
const colorPoisonCherryHex = colorPalette.status.poisonCherry.hex;
const colorCherryHex = colorPalette.status.cherry.hex;
const secondaryColor = colorPalette.basic.textSecondary.rgba;
const labelPadding = "translate(16px, -6px) scale(0.75)";
const colorWhite = colorPalette.basic.white.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;

const textStyles = {
  asterisk: {
    color: colorCherryHex,
  },
  charCount: {
    position: "absolute",
    right: 0,
  },
  helperWrapper: {
    display: "flex",
    justifyContent: "space-between",
    "& div": {
      display: "flex",
      alignItems: "center",
    },
  },
  error: {
    color: colorPoisonCherryHex,
  },
  formControl: {
    width: "100%",
    "&.gx-text-field-rounded": {
      borderRadius: "4px",
    },
  },
  formControlGCOM3677: {
    width: "100%",
    "&.gx-text-field-rounded": {
      borderRadius: "4px",
    },
    "&.gx-text-field-form-control": {
      backgroundColor: "transparent",
    },
    "&.gx-text-field-form-control .MuiOutlinedInput-root": {
      backgroundColor: colorWhite,
    },
  },
  fontUpdate: {
    fontFamily: `"Graphik", "Roboto", "Helvetica Neue" !important`,
  },
  text: {
    "&.gx-text-field-container": {
      "&.error": {
        "& .MuiFormHelperText-contained, .MuiInputLabel-root": {
          color: colorPoisonCherryHex,
        },
        "&& fieldset": {
          borderColor: colorCherryHex,
        },
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: 0,
        marginRight: 0,
        "&$charCount": {
          marginRight: 12,
          marginTop: 14,
        },
      },
      "&.show-char-count": {
        "& input": {
          paddingRight: 75,
        },
      },
      "&.filled": {
        "& label": {
          marginTop: 0,
          transform: labelPadding,
        },
      },
      "& label": {
        "&.Mui-disabled": {
          color: ironHex,
          textFillColor: ironHex,
        },
        "&.Mui-focused": {
          color: defaultCta,
          transform: labelPadding,
        },
        "&:not(.Mui-focused)": {
          marginTop: -5,
          color: ironHex,
        },
      },
      "& div:first-of-type": {
        borderRadius: 0,
        color: carbonHex,
        fontSize: 16,
        height: 48,
        letterSpacing: "0.25px",
        lineHeight: "24px",
        "&.Mui-focused": {
          "& fieldset": {
            borderColor: defaultCta,
            borderWidth: 2,
          },
        },
        "&.Mui-disabled": {
          "& fieldset": {
            border: "1px dashed",
            borderColor: ironHex,
          },
        },
        "&:hover": {
          "& fieldset": {
            borderColor: stoneHex,
          },
        },
      },
      "& input": {
        fontSize: 16,
        lineHeight: "24px",
        "&.Mui-disabled": {
          textFillColor: ironHex,
        },
        "&::-ms-input-placeholder": {
          color: secondaryColor,
          opacity: 1,
        },
        "&::placeholder": {
          color: secondaryColor,
          opacity: 1,
        },
      },
      "&.hide-label": {
        "& label": {
          "&.Mui-focused": {
            display: "none",
          },
        },
        "& div:first-of-type": {
          "&.Mui-focused": {
            "& legend": {
              visibility: "hidden",
              maxWidth: "0.01px",
            },
          },
        },
        "&.filled": {
          "& label": {
            display: "none",
          },
          "& legend": {
            visibility: "hidden",
            maxWidth: "0.01px",
          },
        },
      },
      "&.multilinev2": {
        "& div:first-of-type": {
          height: "unset",
          padding: "8px 4px 8px 0",
        },
        "&.resize-vertical": {
          "& textarea": {
            resize: "vertical",
          },
        },
        "& textarea": {
          maxHeight: 132,
          maxWidth: 600,
          minHeight: 24,
          minWidth: 112,
          padding: "4px 10px",
        },
        "&.show-char-count": {
          "& textarea": {
            paddingRight: 80,
          },
          "&.multiple-lines": {
            "& $charCount": {
              bottom: 7,
            },
            "& div:first-of-type": {
              paddingBottom: 32,
            },
          },
        },
        "&:not(.show-char-count)": {
          "& textarea": {
            overflowY: "auto !important",
          },
        },
        "& .gx-max-allowed-chars-label": {
          paddingRight: 8,
        },
      },
    },
    "&.gx-text-field-container-rounded": {
      "&.gx-text-field-container": {
        "&.error": {
          "& .MuiFormHelperText-contained, .MuiInputLabel-root": {
            borderRadius: "4px",
          },
        },
        "& div:first-of-type": {
          borderRadius: "4px",
          "&.Mui-focused": {
            "& fieldset": {
              borderRadius: "4px",
            },
          },
          "&.Mui-disabled": {
            "& fieldset": {
              borderRadius: "4px",
            },
          },
          "&:hover": {
            "& fieldset": {
              borderRadius: "4px",
            },
          },
        },
        "& input": {
          borderRadius: "4px",
        },
      },
    },
  },
};

export { textStyles };
