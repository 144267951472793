import React from "react";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import App from "./app.component";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { AppContextProvider } from "./app.context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HandleRedirects from "./handle-redirects";
import AppPreview from "./app-preview.component";

const generateClassName = createGenerateClassName({
  seed: "swha",
});

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <BrowserRouter>
          <Routes>
            <Route
              path={"/s/questionnaire/:initiativeId/product/:initProductId/:app"}
              element={
                <AppContextProvider>
                  <App />
                </AppContextProvider>
              }
            />
            <Route
              path={"/s/evaluation/:initiativeId/questionnaire/preview/:tab"}
              element={
                <AppContextProvider>
                  <AppPreview isSetupMode={false} />
                </AppContextProvider>
              }
            />
            <Route
              path={"/s/evaluation/:initiativeId/questionnaire/setup/preview/:tab"}
              element={
                <AppContextProvider>
                  <AppPreview isSetupMode={true} />
                </AppContextProvider>
              }
            />
            <Route
              path={"/s/evaluation/:initiativeId/questionnaire/setup/:surveyId/preview/:tab"}
              element={
                <AppContextProvider>
                  <AppPreview isSetupMode={true} />
                </AppContextProvider>
              }
            />
            <Route path={"*"} element={<HandleRedirects />} />
          </Routes>
        </BrowserRouter>
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
