import { Collapse, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { getAsyncRequest, InitState, useCaptureEventsV2 } from "gx-npm-lib";
import React, { Fragment, useContext, useEffect } from "react";
import BrandingHeader from "./ui/branding-header/branding-header.component";
import ClosedSurveyHeader from "./ui/closed-survey-header/closed-survey-header.component";
import Snackbar from "./ui/snack-bar/snack-bar.component";
import SurveyHeader from "./ui/survey-header/survey-header.component";
import { SurveyHeaderContextProvider } from "./ui/survey-header/survey-header.context";
import TabMenu from "./ui/tab-menu/tab-menu.component";
import { AppContext } from "./app.context";
import styles from "./app.styles";
import { QuickStartPopperComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import OverviewComponent from "./ui/quick-start/overview/overview.component";
import SharingResponsesComponent from "./ui/quick-start/sharing-responses/sharing-responses.component";
import DataPrivacyComponent from "./ui/quick-start/data-privacy/data-privacy.component";
import { ClientEvents } from "./app.constants";

const useStyles = makeStyles(() => styles);
const App = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const captureEvents = useCaptureEventsV2();
  const quickStartTabsData = [
    { label: "Overview", content: <OverviewComponent /> },
    { label: "Sharing Responses", content: <SharingResponsesComponent /> },
    { label: "Data Privacy", content: <DataPrivacyComponent /> },
  ];
  const {
    isScrolling,
    initId,
    initProdId,
    initState,
    isOnboardingDrawerOpen,
    setInitState,
    setIsHeaderDataLoaded,
    setIsOnboardingDrawerOpen,
    setIsScrolling,
    setIsSnackbarOpen,
    setIsSurveyLegalDismissed,
    setSurveyId,
    setSurveyHasRequestedDocs,
    setSurveyRequestedByCompanyName,
    setSurveyRequestedByEmail,
    setSurveyRequestedByUserName,
    setSurveyStatus,
  } = useContext(AppContext);

  const clientEvents = [
    ClientEvents.SURVEY_HEADER_QUICKSTART_OVERVIEW_CLICKED,
    ClientEvents.SURVEY_HEADER_QUICKSTART_SHARING_RESPONSE_CLICKED,
    ClientEvents.SURVEY_HEADER_QUICKSTART_DATA_PRIVACY_CLICKED,
  ];

  const handleEventCapture = (tabIndex: number) => {
    captureEvents([
      {
        eventType: clientEvents[tabIndex],
        metaData: {
          initiativeId: initId,
          initProdId,
        },
      },
    ]);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setIsScrolling]);

  useEffect(() => {
    if (!initId || !initProdId) {
      return;
    }
    (async () => {
      const url = `/api/v2/initiatives/${initId}/survey-recipients/${initProdId}/header-data`;
      const response = await getAsyncRequest(url);
      if (response?.status !== 200 || !response?.data.data || typeof response.data.data !== "object") {
        setIsSnackbarOpen(true);
        return;
      }
      const payload = response.data.data;
      setInitState(payload.initState || InitState.ACTIVE);
      setIsHeaderDataLoaded(true);
      setIsSurveyLegalDismissed(payload.isSurveyLegalDismissed);
      setSurveyHasRequestedDocs(payload.hasRequestedDocuments);
      setSurveyId(payload.surveyId || "");
      setSurveyRequestedByCompanyName(payload.requestedByCompanyName || "");
      setSurveyRequestedByEmail(payload.requestedByEmail || "");
      setSurveyRequestedByUserName(payload.requestedByUserName || "");
      setSurveyStatus(payload.surveyStatus);
      setTimeout(() => {
        if (payload.showOnboarding) {
          captureEvents([
            {
              eventType: ClientEvents.SURVEY_HEADER_QUICKSTART_MODAL_OPENED,
              metaData: {
                initiativeId: initId,
                initProdId,
              },
            },
          ]);
        }
        setIsOnboardingDrawerOpen(payload.showOnboarding);
      }, 2000);
    })();
  }, [
    captureEvents,
    initId,
    initProdId,
    setInitState,
    setIsHeaderDataLoaded,
    setIsOnboardingDrawerOpen,
    setIsSnackbarOpen,
    setIsSurveyLegalDismissed,
    setSurveyHasRequestedDocs,
    setSurveyId,
    setSurveyRequestedByCompanyName,
    setSurveyRequestedByEmail,
    setSurveyRequestedByUserName,
    setSurveyStatus,
  ]);

  return (
    <Fragment>
      <div className={classes.headerContent}>
        <ClosedSurveyHeader />
        <BrandingHeader />
        <div className={classNames(classes.collapseContainer, isScrolling && classes.collapsedScrolling)}>
          <Collapse in={!isScrolling}>
            <SurveyHeaderContextProvider>
              <SurveyHeader />
            </SurveyHeaderContextProvider>
          </Collapse>
          <TabMenu />
        </div>
      </div>
      <div
        className={classNames(
          classes.staticHeaderSpacer,
          [InitState.ARCHIVED, InitState.ARCHIVED].includes(initState) && classes.closedStatusSpacer,
          isScrolling && classes.scrollingSpacer
        )}
      />
      <Snackbar />

      <QuickStartPopperComponent
        title={t("Vendor questionnaire experience")}
        isOpen={isOnboardingDrawerOpen}
        onClose={() => {
          captureEvents([
            {
              eventType: ClientEvents.SURVEY_HEADER_QUICKSTART_MODAL_CLOSED,
              metaData: {
                initiativeId: initId,
                initProdId,
              },
            },
          ]);
          setIsOnboardingDrawerOpen(false);
        }}
        tabsData={quickStartTabsData}
        onTabClick={handleEventCapture}
      />
    </Fragment>
  );
};

export default App;
