import {
  Button,
  ButtonLoader,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./share-responses-dialog.styles";
import { useTranslation } from "react-i18next";
import { SurveyHeaderContext } from "../survey-header.context";
import { makeStyles } from "@material-ui/core";
import { isValidResponse, postAsyncRequest } from "gx-npm-lib";
import { AppContext } from "../../../app.context";
import { SurveyStatus } from "../../../app.constants";
import React, { useContext } from "react";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flag";

const useStyles = makeStyles(() => styles);

const ShareResponsesDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initId, initProdId, surveyId, setSurveyStatus, setIsSnackbarOpen } = useContext(AppContext);
  const {
    isShareResponsesDialogOpen,
    isShareResponsesRequestProcessing,
    setIsShareResponsesDialogOpen,
    setIsShareResponsesRequestProcessing,
  } = useContext(SurveyHeaderContext);

  const handleShareResponses = () => {
    setIsShareResponsesRequestProcessing(true);
    (async () => {
      const response = await postAsyncRequest(
        `/api/v2/initiatives/${initId}/survey-responses/recipient/${surveyId}/${initProdId}/share-responses`
      );
      if (!isValidResponse(response)) {
        setIsSnackbarOpen(true);
        setIsShareResponsesRequestProcessing(false);
        return;
      }
      setIsShareResponsesDialogOpen(false);
      setSurveyStatus(SurveyStatus.SUBMITTED);
      setIsShareResponsesRequestProcessing(false);
    })();
  };

  return (
    <Dialog
      open={isShareResponsesDialogOpen}
      onClose={() => setIsShareResponsesDialogOpen(false)}
      title={t("Share your responses?")}
      body={
        <div>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p2"} color={"coal"} boldness={"regular"}>
                {t(
                  "Once you share your responses with the evaluation team, any changes you make to your responses will automatically be visible to them. This cannot be undone, do you wish to continue?"
                )}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p>
                {t(
                  "Once you share your responses with the evaluation team, any changes you make to your responses will automatically be visible to them. This cannot be undone, do you wish to continue?"
                )}
              </p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      }
      footer={
        <div className={classes.dialogFooter}>
          <Button rootClassName="btn-tertiary" onClick={() => setIsShareResponsesDialogOpen(false)}>
            {t("CANCEL")}
          </Button>
          <div className={classes.dialogConfirmButton}>
            <ButtonLoader
              btnClass="btn-primary"
              btnRootClassName="gx-btn-share-responses-confirm"
              handleButtonClick={handleShareResponses}
              isLoading={isShareResponsesRequestProcessing}
            >
              {t("SHARE RESPONSES")}
            </ButtonLoader>
          </div>
        </div>
      }
    />
  );
};

export default ShareResponsesDialog;
