import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../app.context";
import styles from "./closed-survey-header.styles";
import {
  Paragraph,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { InitState } from "gx-npm-lib";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

const useStyles = makeStyles(() => styles);
const ClosedSurveyHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { initState } = useContext(AppContext);
  return (
    <section
      aria-label={t("survey closed banner")}
      className={classNames(
        classes.closedHeaderContainer,
        [InitState.ARCHIVED, InitState.DELETED].includes(initState) && "is-closed"
      )}
    >
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={classNames(classes.copyContainerGCOM3606)}
            styling={"p3"}
            color={"coal"}
            boldness={"medium"}
          >
            {t("This questionnaire has been closed.")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph boldness="medium" rootClassName={classNames(classes.copyContainer)} type="p3">
            {t("This questionnaire has been closed.")}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </section>
  );
};

export default ClosedSurveyHeader;
