const spinningSaveIndicatorStyles = {
  root: {
    "& .save-indicator-icon": {
      height: "unset",
      paddingTop: "5px",
    },
  },
};

export default spinningSaveIndicatorStyles;
