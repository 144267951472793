import { SnackbarBanner } from "gx-npm-ui";
import { AppContext } from "../../app.context";
import { useContext } from "react";

const Snackbar = () => {
  const { isSnackbarOpen, setIsSnackbarOpen } = useContext(AppContext);

  return (
    <SnackbarBanner isOpen={isSnackbarOpen} setIsOpen={() => setIsSnackbarOpen(false)} isDefaultErrorMessage={true} />
  );
};

export default Snackbar;
