import { colorPalette, weightPalette } from "gx-npm-common-styles";

const surveyHeaderStyles = {
  surveyRoot: {
    color: `var(${colorPalette.neutrals.carbon.name})`,
    display: "block",
    height: "92px",
    margin: "0 auto",
    maxWidth: "1440px",
    minWidth: "1024px",
    padding: "24px 56px",
    position: "relative" as const,
    transition: "all 0.3s ease-in-out",
    zIndex: 1104,
    "@media (max-width:1024px)": {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    "&$surveyRootScrolling": {
      height: "32px",
    },
  },
  surveyRootScrolling: {},
  surveyContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  surveyLeftContainer: {
    alignItems: "center",
    display: "flex",
    maxWidth: "600px",
  },
  surveyRightContainer: {
    alignItems: "center",
    display: "flex",
    "& .gx-button": {
      marginLeft: "18px",
      marginRight: "10px",
    },
  },
  surveyClipboardImage: {
    backgroundColor: `var(${colorPalette.basic.white.name})`,
    borderRadius: "8px",
    padding: "14px",
  },
  surveyRequestedByTextWrapper: {
    marginLeft: "20px",
  },
  headerDataResponsesSharedContainer: {
    display: "flex",
    alignItems: "center",
  },
  surveyResponsesSharedSeparator: {
    backgroundColor: `var(${colorPalette.neutrals.stone.name})`,
    height: "24px",
    marginLeft: "11px",
    marginRight: "24px",
    width: "1px",
  },
  surveyResponsesSharedText: {
    color: `var(${colorPalette.neutrals.iron.name})`,
    fontSize: "14px",
    fontVariationSettings: weightPalette.medium.variation,
    fontWeight: weightPalette.medium.amount,
    letterSpacing: "0.25px",
    lineHeight: "150%",
  },
  surveyRequestedByCompanyNameText: {
    height: "30px",
    color: `var(${colorPalette.neutrals.carbon.name})`,
    fontSize: "18px",
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
    lineHeight: "140%",
    letterSpacing: "0.25px",
  },
  surveyRequestedByCompanyNameTextGCOM3606: {
    height: "30px",
  },
  surveyRequestedByText: {
    color: `var(${colorPalette.neutrals.coal.name})`,
    fontSize: "12px",
    fontVariationSettings: weightPalette.regular.variation,
    fontWeight: weightPalette.regular.amount,
    letterSpacing: "0.35px",
    lineHeight: "150%",
    marginTop: "5px",
  },
  surveyRequestedByUserNameText: {
    color: `var(${colorPalette.interactions.defaultCta.name})`,
    fontSize: "14px",
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
    letterSpacing: "0.25px",
    lineHeight: "120%",
    textDecoration: "underline",
  },
  surveyRequestedByUserNameTextGCOM3606: {
    textDecoration: "underline",
  },
  surveyResponsesShareButton: {
    "&.gx-button": {
      marginRight: 0,
    },
  },
  toolTipClass: {
    maxWidth: "242px",
  },
};

export default surveyHeaderStyles;
