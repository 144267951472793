import { useTranslation } from "react-i18next";
import { SaveIndicator } from "gx-npm-ui";
import { makeStyles } from "@material-ui/core";
import { AppContext } from "../../app.context";
import styles from "./spinning-save-indicator.styles";
import { useContext } from "react";

const useStyles = makeStyles(() => styles);

const SpinningSaveIndicator = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { savingCount } = useContext(AppContext);

  return (
    <SaveIndicator
      isSaving={savingCount > 0}
      labelSaved={t("Changes saved")}
      labelSaving={t("Saving...")}
      rootClassName={classes.root}
    />
  );
};
export default SpinningSaveIndicator;
