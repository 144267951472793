import { AppPaths, NewAppPaths } from "../../app.constants";
import { TabNames } from "../../app.types";
import { UUID } from "gx-npm-lib";

export const tabIndexToDataNewRoute = Object.freeze([
  {
    name: TabNames.OVERVIEW as TabNames,
    pathName: NewAppPaths.QUESTIONNAIRE_OVERVIEW_TAB,
    url: (initId: UUID, initProdId: UUID) =>
      `/s/questionnaire/${initId}/product/${initProdId}${NewAppPaths.QUESTIONNAIRE_OVERVIEW_TAB}`,
  },
  {
    name: TabNames.QUESTIONNAIRE as TabNames,
    pathName: NewAppPaths.QUESTIONNAIRE_RESPONSES_TAB,
    url: (initId: UUID, initProdId: UUID) =>
      `/s/questionnaire/${initId}/product/${initProdId}${NewAppPaths.QUESTIONNAIRE_RESPONSES_TAB}`,
  },
  {
    name: TabNames.DOCUMENTS as TabNames,
    pathName: NewAppPaths.QUESTIONNAIRE_DOCUMENTS_TAB,
    url: (initId: UUID, initProdId: UUID) =>
      `/s/questionnaire/${initId}/product/${initProdId}${NewAppPaths.QUESTIONNAIRE_DOCUMENTS_TAB}`,
  },
]);

export const tabIndexToData = Object.freeze([
  {
    name: TabNames.OVERVIEW as TabNames,
    pathName: AppPaths.QUESTIONNAIRE_OVERVIEW_TAB,
    url: (initId: UUID, initProdId: UUID) => `${AppPaths.QUESTIONNAIRE_OVERVIEW_TAB}?id=${initId}&pid=${initProdId}`,
  },
  {
    name: TabNames.QUESTIONNAIRE as TabNames,
    pathName: AppPaths.QUESTIONNAIRE_RESPONSES_TAB,
    url: (initId: UUID, initProdId: UUID) => `${AppPaths.QUESTIONNAIRE_RESPONSES_TAB}?id=${initId}&pid=${initProdId}`,
  },
  {
    name: TabNames.DOCUMENTS as TabNames,
    pathName: AppPaths.QUESTIONNAIRE_DOCUMENTS_TAB,
    url: (initId: UUID, initProdId: UUID) => `${AppPaths.QUESTIONNAIRE_DOCUMENTS_TAB}?id=${initId}&pid=${initProdId}`,
  },
]);
