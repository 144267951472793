// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mKkdbsYBViaJFuvF7SHu{width:100%;background:var(--color-neutrals-quartz);position:fixed;top:120px;bottom:0;left:0;right:0;z-index:1101;display:flex;justify-content:center}.mKkdbsYBViaJFuvF7SHu .HE6745Mt4F9ufo1yBbAg{margin-top:156px;width:503px;text-align:center}.mKkdbsYBViaJFuvF7SHu .HE6745Mt4F9ufo1yBbAg img{width:367.53px}.mKkdbsYBViaJFuvF7SHu .GieoNqflSiQgod7h6phA{color:var(--color-neutrals-iron);margin-top:36px;font-size:24px;text-align:center}.mKkdbsYBViaJFuvF7SHu .DaAlNrMOVLWo8QdWhpXt{margin-top:36px;text-align:center}.mKkdbsYBViaJFuvF7SHu .PGanNeo59SNuQYSUIRrX{position:absolute;bottom:0;width:100%}`, "",{"version":3,"sources":["webpack://./src/ui/preview-error/preview-error.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,uCAAA,CACA,cAAA,CACA,SAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CAEA,4CACE,gBAAA,CACA,WAAA,CACA,iBAAA,CAEA,gDACE,cAAA,CAIJ,4CACE,gCAAA,CACA,eAAA,CACA,cAAA,CACA,iBAAA,CAGF,4CACE,eAAA,CACA,iBAAA,CAGF,4CACE,iBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".container {\n  width: 100%;\n  background: var(--color-neutrals-quartz);\n  position: fixed;\n  top: 120px;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 1101;\n  display: flex;\n  justify-content: center;\n\n  .errorContainer {\n    margin-top: 156px;\n    width: 503px;\n    text-align: center;\n\n    img {\n      width: 367.53px;\n    }\n  }\n\n  .paragraph {\n    color: var(--color-neutrals-iron);\n    margin-top: 36px;\n    font-size: 24px;\n    text-align: center;\n  }\n\n  .paragraphGCOM3606 {\n    margin-top: 36px;\n    text-align: center;\n  }\n\n  .footer {\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `mKkdbsYBViaJFuvF7SHu`,
	"errorContainer": `HE6745Mt4F9ufo1yBbAg`,
	"paragraph": `GieoNqflSiQgod7h6phA`,
	"paragraphGCOM3606": `DaAlNrMOVLWo8QdWhpXt`,
	"footer": `PGanNeo59SNuQYSUIRrX`
};
export default ___CSS_LOADER_EXPORT___;
