import { createContext, ReactNode, useState } from "react";
import { SurveyHeaderContextValue } from "./survey-header.types";

const SurveyHeaderContext = createContext<SurveyHeaderContextValue>({} as SurveyHeaderContextValue);

const SurveyHeaderContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [isShareResponsesDialogOpen, setIsShareResponsesDialogOpen] = useState(false);
  const [isShareResponsesRequestProcessing, setIsShareResponsesRequestProcessing] = useState(false);

  const contextValue = {
    isShareResponsesDialogOpen,
    isShareResponsesRequestProcessing,
    setIsShareResponsesDialogOpen,
    setIsShareResponsesRequestProcessing,
  };

  return <SurveyHeaderContext.Provider value={contextValue}>{props.children}</SurveyHeaderContext.Provider>;
};

export { SurveyHeaderContext, SurveyHeaderContextProvider };
