// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NssJ68JCSqdIZFd3dbUe{margin-top:32px;max-width:600px}.NssJ68JCSqdIZFd3dbUe .m0Ia8XFUkMGQJojOYsmU{margin-top:16px}.dqyRpJrutI9jZokDWQJd{margin-top:32px}.nOZWAbSeMqBDJmpUP4jw{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/ui/quick-start/data-privacy/data-privacy.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,eAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .additionalInfo {\n    margin-top: 16px;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `NssJ68JCSqdIZFd3dbUe`,
	"additionalInfo": `m0Ia8XFUkMGQJojOYsmU`,
	"helpLink": `dqyRpJrutI9jZokDWQJd`,
	"loader": `nOZWAbSeMqBDJmpUP4jw`
};
export default ___CSS_LOADER_EXPORT___;
