// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._55ETiYIjaRghebwBCiV .gx-dialog-body{margin-left:-36px;width:600px}.ygM8qIqeGyjLA3iwuI87{padding:24px 36px 56px 36px}.ym7vxit1RP0VDc8hBjn9{background:var(--color-interactions-light-berry);padding:56px 0 74px 36px}.ym7vxit1RP0VDc8hBjn9 .uWGNOoZgS2rxheaJK2V_{display:block}.ym7vxit1RP0VDc8hBjn9 .uwHP1eT9G1IifvB1QJTM{position:relative}.ym7vxit1RP0VDc8hBjn9 .uwHP1eT9G1IifvB1QJTM .vynA5NDpnLFNj9YRxnpr{bottom:-74px;position:absolute;right:0}.gvRjeskBLhUP683PRffu{align-items:center;display:flex;justify-content:flex-end}.gvRjeskBLhUP683PRffu .DLvOnna_pdMHALse8sqP{margin-right:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-init-create-modal/free-trial-init-create-modal.styles.module.scss"],"names":[],"mappings":"AACE,sCACE,iBAAA,CACA,WAAA,CAIJ,sBACE,2BAAA,CAGF,sBACE,gDAAA,CACA,wBAAA,CAEA,4CACE,aAAA,CAEF,4CACE,iBAAA,CAEA,kEACE,YAAA,CACA,iBAAA,CACA,OAAA,CAKN,sBACE,kBAAA,CACA,YAAA,CACA,wBAAA,CAEA,4CACE,iBAAA","sourcesContent":[".dialogRoot {\n  & :global(.gx-dialog-body) {\n    margin-left: -36px;\n    width: 600px;\n  }\n}\n\n.subtitleParagraph {\n  padding: 24px 36px 56px 36px\n}\n\n.remainingInitsHeading {\n  background: var(--color-interactions-light-berry);\n  padding: 56px 0 74px 36px;\n\n  & .headingSecondLine {\n    display: block;\n  }\n  & .cornerImgContainer {\n    position: relative;\n\n    & .cornerImg {\n      bottom: -74px;\n      position: absolute;\n      right: 0;\n    }\n  }\n}\n\n.footerButtonContainer {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n\n  & .cancelButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogRoot": `_55ETiYIjaRghebwBCiV`,
	"subtitleParagraph": `ygM8qIqeGyjLA3iwuI87`,
	"remainingInitsHeading": `ym7vxit1RP0VDc8hBjn9`,
	"headingSecondLine": `uWGNOoZgS2rxheaJK2V_`,
	"cornerImgContainer": `uwHP1eT9G1IifvB1QJTM`,
	"cornerImg": `vynA5NDpnLFNj9YRxnpr`,
	"footerButtonContainer": `gvRjeskBLhUP683PRffu`,
	"cancelButton": `DLvOnna_pdMHALse8sqP`
};
export default ___CSS_LOADER_EXPORT___;
