const actionDialogStyles = {
  dialogConfirmButton: {
    marginLeft: 24,
  },
  dialogFooter: {
    display: "flex" as const,
    float: "right" as const,
  },
};

export default actionDialogStyles;
