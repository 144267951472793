import classNames from "classnames";
import { LightningBolt, LightningBoltExpanded, RibbonBulbIcon } from "gx-npm-icons";
import { KeyboardEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "../typography/typography.component";
import ResearchSlideIn from "./researchSlideIn/research-slide-in.component";
import styles from "./research-ribbon-v2.styles.module.scss";
import { GCOM_3476_3523__researchRibbonUpdate } from "../feature-flags";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  useFeatureFlag,
} from "../featureFlags";

type ResearchRibbonV2Props = {
  autoExpand: boolean;
  magicQuadrantResId: number;
  marketGuideResId: number;
  piMarketSeoName: string;
  templateName: string;
};
const ResearchRibbonV2Component = ({
  autoExpand = false,
  magicQuadrantResId = -1,
  marketGuideResId = -1,
  piMarketSeoName = "",
  templateName = "",
}: ResearchRibbonV2Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShifting, setIsShifting] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isGCOM3523FF = useFeatureFlag(GCOM_3476_3523__researchRibbonUpdate);

  useEffect(() => {
    if (isExpanded) {
      const autoCloseTimer = window.setTimeout(() => handleMouseLeave(), 3000);
      const shiftingTimer = window.setTimeout(() => setIsShifting(true), 300);
      const visibleTextTimer = window.setTimeout(() => setIsTextVisible(true), 250);
      return () => {
        clearTimeout(autoCloseTimer);
        clearTimeout(shiftingTimer);
        clearTimeout(visibleTextTimer);
      };
    }
  }, [isExpanded]);

  useEffect(() => {
    let autoCloseTimer: number;
    if (autoExpand) {
      localStorage.setItem("showRibbonAnimation", "false");
      setIsExpanded(true);
      autoCloseTimer = window.setTimeout(() => handleMouseLeave(), 3000);
    }
    return () => {
      clearTimeout(autoCloseTimer);
    };
  }, [autoExpand]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleOpenDrawer();
    }
  };

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    const timeout = window.setTimeout(() => setIsShifting(false), 300);
    setTimeout(() => setIsTextVisible(false), 300);
    setIsExpanded(false);
    return () => clearTimeout(timeout);
  };

  const handleOpenDrawer = () => {
    handleMouseLeave();
    setIsDrawerOpen(true);
  };

  return (
    <section>
      <div
        className={classNames(
          styles.container,
          isExpanded && styles.gxRibbonExpanded,
          isGCOM3523FF && styles.containerGCOM3523
        )}
        onClick={handleOpenDrawer}
        onKeyDown={handleKeyDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="button"
        tabIndex={0}
      >
        <FeatureFlagBooleanContainer flagName={GCOM_3476_3523__researchRibbonUpdate}>
          <FeatureFlagBooleanOff>{isShifting ? <LightningBoltExpanded /> : <LightningBolt />}</FeatureFlagBooleanOff>
          <FeatureFlagBooleanOn>
            {isShifting ? <RibbonBulbIcon width="32" height="32" /> : <RibbonBulbIcon />}
          </FeatureFlagBooleanOn>
        </FeatureFlagBooleanContainer>
        <TypographyComponent
          boldness="semi"
          rootClassName={classNames(
            styles.ribbonCopy,
            isTextVisible && styles.show,
            isGCOM3523FF && styles.ribbonCopyGCOM3523
          )}
          styling={"p4"}
        >
          {t("Gartner research behind this market")}
        </TypographyComponent>
      </div>
      <ResearchSlideIn
        handleOnClick={() => setIsDrawerOpen(!isDrawerOpen)}
        isDrawerOpen={isDrawerOpen}
        isMagicQ={magicQuadrantResId > 0}
        mqResId={magicQuadrantResId}
        mgResId={marketGuideResId}
        piMarketSeoName={piMarketSeoName}
        templateName={templateName}
      />
    </section>
  );
};

export { ResearchRibbonV2Component };
