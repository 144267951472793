import {
  MiniButton,
  Paragraph,
  SnackbarBanner,
  TooltipV2,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { EyeIcon } from "gx-npm-icons";
import React, { useContext, useState } from "react";
import classes from "./preview-banner.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { InitUserRole, postAsyncRequest, useUserInitAccess } from "gx-npm-lib";
import { AppContext } from "../../app.context";
import { SendIcon } from "../../assets/icons/send";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

const PreviewBanner = ({ isSetupMode }: { isSetupMode: boolean }) => {
  const { t } = useTranslation();
  const { surveyRequestedByCompanyName, initId } = useContext(AppContext);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [isFailureSnackbarOpen, setIsFailureSnackbarOpen] = useState(false);
  const initAccess = useUserInitAccess(initId);
  const sendTestEmail = async () => {
    const url = `/api/v2/initiatives/${initId}/survey-preview/email`;
    try {
      const response = await postAsyncRequest(url, {
        companyName: surveyRequestedByCompanyName,
      });
      if (response.status === 201) {
        setIsSuccessSnackbarOpen(true);
      } else {
        setIsFailureSnackbarOpen(true);
      }
    } catch (e) {
      setIsFailureSnackbarOpen(true);
    }
  };
  return (
    <div className={classes.previewHeader}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={classes.previewHeaderContentGCOM3606}
            styling={"p3"}
            color={"coal"}
            boldness={"medium"}
          >
            <EyeIcon />
            {t("This is a preview. Any changes made here will not affect your actual questionnaire.")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph rootClassName={classes.previewHeaderContent} boldness={"medium"} type={"p3"}>
            <EyeIcon />
            {t("This is a preview. Any changes made here will not affect your actual questionnaire.")}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>

      {!isSetupMode && (
        <TooltipV2
          title={t("This action is only available to evaluation owners.")}
          placement="bottom"
          deactivate={initAccess.role === InitUserRole.OWNER}
        >
          <div className={classNames(classes.sendEmailPreviewBtn)}>
            <MiniButton disabled={initAccess.role !== InitUserRole.OWNER} onClick={sendTestEmail}>
              <SendIcon />
              {t("Email me a test")}
            </MiniButton>
          </div>
        </TooltipV2>
      )}
      <SnackbarBanner
        isOpen={isSuccessSnackbarOpen}
        setIsOpen={setIsSuccessSnackbarOpen}
        type={"MINI_SUCCESS"}
        message={t("Test email sent")}
      />
      <SnackbarBanner
        isOpen={isFailureSnackbarOpen}
        setIsOpen={setIsFailureSnackbarOpen}
        type={"ERROR"}
        message={t("Failed to send email")}
      />
    </div>
  );
};

export default PreviewBanner;
