// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yLbdTwod5nEpOVzj9e6P{margin-top:24px}.E20x_1FfYYpVhRE_tg_P{margin-top:32px;max-width:600px}.E20x_1FfYYpVhRE_tg_P .l_QlnJJ6Rn_hLS2N5kkd{margin-top:16px}.dPFY0PwTfZm32bhuGruL{margin-top:32px}.je7jaNEKiR7OgDZrOPa1{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/ui/quick-start/sharing-responses/sharing-responses.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,eAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".sharingResponsesImageContainer {\n  margin-top: 24px;\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .additionalInfo {\n    margin-top: 16px;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sharingResponsesImageContainer": `yLbdTwod5nEpOVzj9e6P`,
	"description": `E20x_1FfYYpVhRE_tg_P`,
	"additionalInfo": `l_QlnJJ6Rn_hLS2N5kkd`,
	"helpLink": `dPFY0PwTfZm32bhuGruL`,
	"loader": `je7jaNEKiR7OgDZrOPa1`
};
export default ___CSS_LOADER_EXPORT___;
