import React, { useContext, useEffect, useRef } from "react";
import drawerStyles from "./onboarding-drawer.styles";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Paragraph,
  Header,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { Drawer } from "@material-ui/core";
import { AppContext } from "../../app.context";
import { CloseIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import VidyardEmbed from "@vidyard/embed-code";
const useStyles = makeStyles(drawerStyles);
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

const OnBoardingDrawer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const { surveyRequestedByCompanyName, setIsOnboardingDrawerOpen, isOnboardingDrawerOpen } = useContext(AppContext);

  useEffect(() => {
    if (!isOnboardingDrawerOpen) {
      return;
    }
    setTimeout(() => {
      VidyardEmbed.api.renderPlayer({ container: videoRef.current, uuid: "7ipTRdHCn3RXhoqUka2zSA" });
    });
  }, [isOnboardingDrawerOpen, videoRef]);

  return (
    <Drawer transitionDuration={500} className={classes.slideInDrawer} anchor={"bottom"} open={isOnboardingDrawerOpen}>
      <div className={classes.closeButton}>
        <IconButton onClick={() => setIsOnboardingDrawerOpen(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classNames(classes.root)}>
        <div className={classNames(classes.descriptionTab)}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <div className={classNames(classes.headerBox)}>
                <TypographyComponent element={"span"} styling={"h3"} color={"yam"} boldness={"medium"}>
                  {surveyRequestedByCompanyName}
                </TypographyComponent>
                <TypographyComponent element={"span"} styling={"h3"} color={"carbon"} boldness={"medium"}>
                  {t(" is using BuySmart for a technology evaluation, and wants to know more about your product.")}
                </TypographyComponent>
              </div>
              <div className={classes.listItemTitle}>
                <TypographyComponent element={"span"} styling={"p2"} color={"carbon"} boldness={"semi"}>
                  {t("With BuySmart you can:")}
                </TypographyComponent>
              </div>
              <ul className={classNames(classes.bulletListGCOM3606)}>
                <li>
                  <TypographyComponent styling={"p2"} color={"coal"} boldness={"regular"}>
                    {t("Respond to questions")}
                  </TypographyComponent>
                </li>
                <li>
                  <TypographyComponent styling={"p2"} color={"coal"} boldness={"regular"}>
                    {t("Upload requested documents")}
                  </TypographyComponent>
                </li>
                <li>
                  <TypographyComponent styling={"p2"} color={"coal"} boldness={"regular"}>
                    {t("Share responses in real time with the evaluation team")}
                  </TypographyComponent>
                </li>
              </ul>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <div className={classNames(classes.headerBox)}>
                <Header type={"h3"}>
                  <span className={classNames(classes.orangeText)}>{surveyRequestedByCompanyName}</span>
                  <span>
                    {t(" is using BuySmart for a technology evaluation, and wants to know more about your product.")}
                  </span>
                </Header>
              </div>
              <div className={classes.listItemTitle}>
                <Paragraph boldness={"semi"} type={"p2"}>
                  {t("With BuySmart you can:")}
                </Paragraph>
              </div>
              <ul className={classNames(classes.bulletList)}>
                <li>
                  <Paragraph type={"p2"}>{t("Respond to questions")}</Paragraph>
                </li>
                <li>
                  <Paragraph type={"p2"}>{t("Upload requested documents")} </Paragraph>
                </li>
                <li>
                  <Paragraph type={"p2"}>{t("Share responses in real time with the evaluation team")} </Paragraph>
                </li>
              </ul>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>

          <div className={classNames(classes.btnWrapper)}>
            <div>
              <Button onClick={() => setIsOnboardingDrawerOpen(false)} rootClassName="btn-primary">
                {t("Got it")}
              </Button>
            </div>
          </div>
        </div>
        <div className={classNames(classes.videoTab)}>
          <div ref={videoRef} />
        </div>
      </div>
    </Drawer>
  );
};

export default OnBoardingDrawer;
